<template>
  <div id="page-paypal">
    <v-container grid-list-xl fluid>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title class="pb-0 pt-0">{{ $vuetify.lang.t('$vuetify.different.filter') }}</v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6" md="2">
                  <v-select
                    v-model="filters.projects"
                    :items="compProjects"
                    :menu-props="{ maxHeight: '400' }"
                    :label="$vuetify.lang.t('$vuetify.different.project')"
                    multiple
                    :hint="$vuetify.lang.t('$vuetify.different.selectProject')"
                    persistent-hint
                    chips
                    clearable
                    item-value="id"
                    item-text="name"
                    prepend-icon="mdi-briefcase"
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>{{
                $vuetify.lang.t('$vuetify.different.paypal')
              }}
            </v-card-title>
            <v-toolbar flat color="white">
              <v-text-field
                text
                solo
                flat
                clearable
                prepend-icon="search"
                :placeholder="$vuetify.lang.t('$vuetify.toolbar.search')"
                v-model="search"
                hide-details
                class="hidden-sm-and-down"
              ></v-text-field>
              <v-btn small rounded @click="get" class="mr-2">
                <v-icon>mdi-refresh</v-icon>
                {{ $vuetify.lang.t('$vuetify.buttons.refresh') }}
              </v-btn>
              <v-btn small rounded color="success" @click="dialog = !dialog">
                <v-icon>mdi-plus</v-icon>
                {{ $vuetify.lang.t('$vuetify.buttons.create') }}
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text class="pa-0">
              <v-data-table
                :headers="compHeaders"
                :items="items"
                fixed-header
                class="elevation-1"
                item-key="id"
                :loading="tableLoading"
                :options.sync="options"
                :server-items-length="totalItems"
              >
                <template v-slot:item.sync="{ item }">
                  <v-simple-checkbox
                    v-model="item.sync"
                    disabled
                  ></v-simple-checkbox>
                </template>
                <template v-slot:item.action="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-show="!item.sync"
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="sync(item)"
                      >
                        <v-icon>mdi-sync</v-icon>
                      </v-btn>
                    </template>
                    <span>{{
                        $vuetify.lang.t('$vuetify.buttons.synchronize')
                      }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="download(item)"
                      >
                        <v-icon>mdi-download</v-icon>
                      </v-btn>
                    </template>
                    <span>{{
                        $vuetify.lang.t('$vuetify.buttons.download')
                      }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-show="!item.sync"
                        icon
                        v-on="on"
                        v-bind="attrs"
                        @click="deleteItem(item)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>{{
                        $vuetify.lang.t('$vuetify.buttons.delete')
                      }}</span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <v-dialog persistent v-model="dialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="itemForm" lazy-validation v-model="valid">
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="item.project_id"
                    :items="compProjects"
                    :label="$vuetify.lang.t('$vuetify.different.project') + '*'"
                    :rules="[rules.required]"
                    required
                    item-value="id"
                    item-text="name"
                    prepend-icon="mdi-briefcase"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <v-file-input
                    show-size
                    :rules="[rules.required]"
                    accept=".csv"
                    :label="$vuetify.lang.t('$vuetify.different.file') + '*'"
                    v-model="file"
                  ></v-file-input>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>*{{ $vuetify.lang.t('$vuetify.different.indicate') }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
            :disabled="itemLoading"
          >
            {{ $vuetify.lang.t('$vuetify.buttons.close') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="save"
            :disabled="!valid"
            :loading="itemLoading"
          >{{ compAction }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" persistent max-width="500px">
      <v-card>
        <v-card-title class="headline">{{
            $vuetify.lang.t('$vuetify.different.deleteItemConfirm')
          }}
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="dialogDelete = false"
            :disabled="itemLoading"
          >
            {{ $vuetify.lang.t('$vuetify.buttons.cancel') }}
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="deleteItemConfirm"
            :loading="itemLoading"
          >{{ $vuetify.lang.t('$vuetify.buttons.delete') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      :timeout="3000"
      bottom
      right
      :color="snackbar.color"
      v-model="snackbar.show"
    >
      {{ snackbar.text }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text @click.native="snackbar.show = false" icon>
          <v-icon>close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <AppLoader :dialog.sync="loading"></AppLoader>
  </div>
</template>

<script>
import AppLoader from '@/components/AppLoader'

export default {
  components: {
    AppLoader
  },
  data() {
    return {
      loading: false,
      dialog: false,
      dialogDelete: false,
      valid: true,
      itemLoading: false,
      tableLoading: false,
      search: null,
      awaitingSearch: false,
      file: null,
      item: {
        id: null,
        file: null,
        project_id: null,
        created_at: null,
        updated_at: null
      },
      emptyItem: {
        id: null,
        file: null,
        project_id: null,
        created_at: null,
        updated_at: null
      },
      rules: {
        required: (v) =>
          !!v || this.$vuetify.lang.t('$vuetify.validation.required')
      },
      totalItems: 0,
      options: {
        sortBy: ['id'],
        sortDesc: [true]
      },
      items: [],
      filters: {
        projects: localStorage.getItem('paypal_projects')
          ? JSON.parse(localStorage.getItem('paypal_projects'))
          : null,
      },
      snackbar: {
        show: false,
        text: '',
        color: ''
      },
      errors: {
        file: null
      },
      defaultErrors: {
        file: null
      }
    }
  },
  metaInfo() {
    return {
      title: this.$vuetify.lang.t('$vuetify.different.paypal')
    }
  },
  watch: {
    dialog: function (val) {
      if (!val) {
        this.item = Object.assign({}, this.emptyItem)
        this.$refs.itemForm.resetValidation()
        this.dialog = null
        this.errors = Object.assign({}, this.defaultErrors)
      }
    },
    dialogDelete: function (val) {
      if (!val) {
        this.item = Object.assign({}, this.emptyItem)
      }
    },
    search(val) {
      if (!val || (val && val.length >= 1)) {
        if (!this.awaitingSearch) {
          setTimeout(() => {
            this.get()
            this.awaitingSearch = false
          }, 1000)
        }
        this.awaitingSearch = true
      }
    },
    item: {
      handler() {
        this.validation()
      },
      deep: true
    },
    options: {
      handler() {
        this.get()
      },
      deep: true
    },
    filters: {
      handler() {
        this.get()
      },
      deep: true
    },
    'filters.projects': function (val) {
      localStorage.setItem('paypal_projects', JSON.stringify(val))
    },
  },
  computed: {
    compAuthHeader() {
      return {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access_token')
        }
      }
    },
    formTitle() {
      return this.item.id
        ? this.$vuetify.lang.t('$vuetify.buttons.edit')
        : this.$vuetify.lang.t('$vuetify.buttons.new')
    },
    compAction() {
      return this.item.id
        ? this.$vuetify.lang.t('$vuetify.buttons.save')
        : this.$vuetify.lang.t('$vuetify.buttons.create')
    },
    compHeaders() {
      return [
        {text: 'ID', value: 'id'},
        {
          text: this.$vuetify.lang.t('$vuetify.different.project'),
          value: 'project.name'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.different.title'),
          value: 'name'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.different.synchronized'),
          value: 'sync'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.dates.createdAt'),
          value: 'created_at'
        },
        {
          text: this.$vuetify.lang.t('$vuetify.buttons.action'),
          value: 'action',
          sortable: false
        }
      ]
    },
    compProjects() {
      return this.$store.getters.getProjects
    }
  },
  methods: {
    deleteItem(item) {
      this.item = Object.assign({}, item)
      this.dialogDelete = true
    },
    deleteItemConfirm() {
      this.itemLoading = true
      let headers = this.compAuthHeader
      headers.headers['Content-Type'] = 'application/json'
      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/paypal/destroy',
          this.item,
          headers
        )
        .then((response) => {
          this.itemLoading = false
          if (response.status === 200) {
            this.get()
            this.dialogDelete = false
            this.showSnackbar('green', this.$vuetify.lang.t('$vuetify.messages.deleted'))
          } else {
            this.showSnackbar('red', this.$vuetify.lang.t('$vuetify.errors.error'))
          }
        })
        .catch(() => {
          this.itemLoading = false
          this.showSnackbar('red', this.$vuetify.lang.t('$vuetify.errors.error'))
        })
    },
    sync(item) {
      this.loading = true
      let headers = this.compAuthHeader
      headers.headers['Content-Type'] = 'application/json'

      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/paypal/sync',
          {id: item.id, project_id: item.project_id},
          headers
        )
        .then((response) => {
          this.loading = false
          if (response.status === 200) {
            this.get()
            this.showSnackbar('green', this.$vuetify.lang.t('$vuetify.messages.sync'))
          } else {
            this.showSnackbar('red', this.$vuetify.lang.t('$vuetify.errors.error'))
          }
        })
        .catch(() => {
          this.loading = false
          this.showSnackbar('red', this.$vuetify.lang.t('$vuetify.errors.error'))
        })
    },
    save() {
      let t = this
      this.errors = Object.assign({}, this.defaultErrors)

      if (this.$refs.itemForm.validate()) {
        let formData = new FormData()
        if (this.file) formData.append('file', this.file)
        formData.append('project_id', this.item.project_id)

        let headers = this.compAuthHeader
        headers.headers['Content-Type'] = 'multipart/form-data'

        this.itemLoading = true

        this.$axios
          .post(this.$store.getters.getApiUrl + 'api/paypal/store', formData, headers)
          .then((response) => {
            this.itemLoading = false
            if (response.status === 200) {
              this.get()
              this.dialog = false
              this.file = null
              this.showSnackbar('green', this.$vuetify.lang.t('$vuetify.messages.saved'))
            } else if (
              response.status === 422 &&
              typeof response.data.errors !== 'undefined'
            ) {
              for (let key in response.data.errors) {
                response.data.errors[key].forEach(function (item) {
                  t.errors[key] = item
                })
              }
            } else {
              this.showSnackbar('red', this.$vuetify.lang.t('$vuetify.errors.error'))
            }
          })
          .catch(() => {
            this.itemLoading = false
            this.showSnackbar('red', this.$vuetify.lang.t('$vuetify.errors.error'))
          })
      }
    },
    get() {
      this.tableLoading = true
      let data = {
        search: this.search,
        page: this.options.page,
        per_page: this.options.itemsPerPage,
        sort_by: this.options.sortBy[0],
        descending: this.options.sortDesc[0],
        filters: this.filters
      }
      let headers = this.compAuthHeader
      headers.headers['Content-Type'] = 'application/json'

      this.$axios
        .post(this.$store.getters.getApiUrl + 'api/paypal/index', data, headers)
        .then((response) => {
          if (response.status === 200) {
            this.items = response.data.data
            this.totalItems = response.data.meta.total
          }
          this.tableLoading = false
        })
        .catch((error) => {
          this.tableLoading = false
        })
    },
    validation() {
      for (let keyItem in this.item) {
        if (this.errors[keyItem]) this.errors[keyItem] = null
      }
    },
    download(item) {
      let config = this.compAuthHeader
      config.responseType = 'blob'
      this.loading = true
      this.$axios
        .post(
          this.$store.getters.getApiUrl + 'api/paypal/download',
          {id: item.id},
          config
        )
        .then((response) => {
          const downloadUrl = window.URL.createObjectURL(
            new Blob([response.data])
          )
          const link = document.createElement('a')
          link.href = downloadUrl
          link.setAttribute('download', item.name)
          document.body.appendChild(link)
          link.click()
          link.remove()
          this.loading = false
        })
        .catch((error) => {
          console.log(error)
          this.loading = false
        })
    },
    showSnackbar(color, text) {
      this.snackbar.show = true
      this.snackbar.color = color
      this.snackbar.text = text
    }
  }
}
</script>
